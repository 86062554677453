import styled from 'styled-components';
import {darkTheme} from '../reset-css'

export const StyledSlider = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 65px auto 65px;
    
    input {
        background-color: transparent;
    }

    .range-max {
        text-align: right;
        font-family: 'Oxanium', sans-serif;
        font-size: 14px;
    }

    .range-min {
        text-align: right;
        font-family: 'Oxanium', sans-serif;
        font-size: 14px;
    }

    > div {
        justify-content: center;
    }
`;

export const StyledExcuse = styled.div`
    width: 100%;
    text-align: center;
    padding: 4em;
`;


export const StyledUtmbGuiWrapper = styled.div`
    margin: auto;
    width: 100%;
    overflow: hidden;

    table {
        overflow: scroll;
        width: fit-content;
        margin: auto;
        text-align: center;

        tr {
            transition: background-color 0.6s;
            background-color: ${({theme}) => theme.body};

            &.table-heading > th {
                color: white;
                font-weight: 700;
            }

            &.table-heading.dark-font > th {
                color: #000D44;
            }
        }

        td, th {
            border: 0 !important;
            border-top: .1rem solid ${({theme}) => theme.semibackground} !important;
        }

        tr:hover:not(.table-heading) {
            background-color: #000D44;
            color: white;
        }
    }

    font-family: 'Oxanium', sans-serif;

    h1, h2, h3, h4, h5, h6 {
        color: ${({theme}) => theme === darkTheme ? 'inherit' : '#000D44'};
        font-weight: 800;
    }

    position: absolute;

    @media (max-width: 500px) {
        h1 {
            font-size: 24px;
        }
    }

    div.distance-field {
        span {
            display: inline-block;
            width: 80px;
        }
    }

    input, select {
        border: none;
        width: 90%;
        background-color: transparent;
        color: ${({theme}) => theme.text};
        font-size: 18px;

        &[type=text] {
            border-bottom: 1px solid ${({theme}) => theme.text};
        }

        option {
            background-color: ${({theme}) => theme.semibackground};
        }
    }

    fieldset {
        background-color: ${({theme}) => theme.semibackground};
        border: none;
        border-radius: 4px;
        user-select: none;
        padding: 1em;
        &.collapsed {
            padding-top: 0;
            padding-bottom: 0;
        }
        legend {
            color: gray;
            font-weight: 700;
        }
    }

    span.checked {
        color: #7DDF64;
    }

    span.unchecked {
        color: #F32525;
    }

    input.min-range {
        color: black;
    }

    div.utmb-search-box {
        fieldset {
            position: relative;
        }

        font-size: 18px;

        svg.collapse {
            position: absolute;
            right: 14px;
            top: -20px;
        }

        span.checkbox {
            margin-right: 1em;
        }

        svg {
            transform: translateY(5px);
        }

        form {
            display: grid;
            grid-template-columns: 1fr;
            @media (min-width: 600px) {
                grid-template-columns: repeat(2, 1fr);
            }
            @media (min-width: 900px) {
                grid-template-columns: repeat(3, 1fr);
            }
            @media (min-width: 1200px) {
                grid-template-columns: repeat(4, 1fr);
            }
        }

    }

    div.page-buttons {
        width: fit-content;
        user-select: none;
        margin: auto;

        span {
            display: inline-block;
            text-align: center;
            width: 100px;
        }

        @media (max-width: 300px) {
            button.button-1, button.button-10 {
                display: none;
            }
        }
        @media (max-width: 600px) {
            button.button-2, button.button-9 {
                display: none;
            }
        }
        @media (max-width: 800px) {
            button.button-3, button.button-8 {
                display: none;
            }
        }

        button {
            color: #0AD2F3;
            font-weight: 700;
            background-color: #000D44;
            border: none;
            transition: transform .2s ease-in-out;

            &.underscore {
                text-decoration: underline;
            }

            &:active {
                transform: scale(0.9);
            }

            width: 40px;
            height: 30px;
            margin: 5px;
        }
    }

    svg {
        border-radius: 4px;

        &:not(.button) {
            height: 22px;
        }

        &.flag {
            box-shadow: 0 0 1rem gray;
        }
    }

    table.search-results {
        .search-title {
            text-align: start;
        }


        @media (max-width: 900px) {
            .search-index {
                display: none;
            }
        }
        @media (max-width: 800px) {
            .search-location {
                display: none;
            }
        }
        @media (max-width: 650px) {
            .search-elevation {
                display: none;
            }
        }
        @media (max-width: 500px) {
            .search-distance {
                display: none;
            }
        }
        @media (max-width: 380px) {
            .search-category {
                display: none;
            }
        }


        tr.table-heading {
            background-color: #000D44;
        }
    }

    h1#summary-title {
        margin-top: .8em;
    }

    div.utmb-overview {
        display: grid;
        grid-template-columns: repeat(6, 1fr);

        p.overview-head {
            color: gray;
            font-size: 14px;
        }

        p:not(.overview-head) {
            font-size: 22px;
        }

        p.overview-elev {
            display: none
        }

        @media (max-width: 500px) {
            grid-template-columns: repeat(2, 1fr);
            p.overview-head {
                display: none;
            }

            p.overview-elev:after {
                content: 'km EG'
            }

            p.overview-elev {
                display: inline
            }

            p.overview-elevation {
                display: none
            }

            p.overview-time:after {
                content: ' AVG '
            }

            p.overview-dnf:after {
                content: ' DNF'
            }
        }
        @media (max-width: 500px) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media (max-width: 320px) {
            grid-template-columns: repeat(2, 1fr);
        }

        justify-items: center;
        border-radius: 4px;

        p {
            font-size: 18px;
        }
    }

    fieldset.race-category, fieldset.race-continent {
        label {
            display: block;
        }
    }

    table.utmb-race {
        text-align: center;
        /* Default table header content */

        th:nth-child(2)::before {
            content: "yr";
        }

        th:nth-child(3)::before {
            content: "dst";
        }

        th:nth-child(4)::before {
            content: "elev";
        }

        th:nth-child(5)::before {
            content: "DNF";
        }

        th:nth-child(6)::before {
            content: "win";
        }

        th:nth-child(7)::before {
            content: "mean";
        }

        th:nth-child(8)::before {
            content: "last";
        }

        th:nth-child(9)::before {
            content: "N";
        }

        th:nth-child(10)::before {
            content: "fem";
        }

        td:nth-child(2)::before {
            content: "'";
        }

        @media (min-width: 1000px) {
            th:nth-child(1)::before {
                content: "Display";
            }

            th:nth-child(2)::before {
                content: "Year";
            }

            th:nth-child(3)::before {
                content: "Distance (km)";
            }

            th:nth-child(4)::before {
                content: "Elevation (m)";
            }

            th:nth-child(5)::before {
                content: "DNF-rate";
            }

            th:nth-child(6)::before {
                content: "Winning finish time";
            }

            th:nth-child(7)::before {
                content: "Mean finish time";
            }

            th:nth-child(8)::before {
                content: "Last finish time";
            }

            th:nth-child(9)::before {
                content: "Number of participants";
            }

            th:nth-child(10)::before {
                content: "Female participants";
            }

            td:nth-child(2)::before {
                content: "20";
            }
        }
        @media (max-width: 650px) {
            td:nth-child(10), th:nth-child(10) {
                display: none;
            }
        }
        @media (max-width: 600px) {
            td:nth-child(8), th:nth-child(8) {
                display: none;
            }
        }
        @media (max-width: 625px) {
            td:nth-child(6), th:nth-child(6) {
                display: none;
            }
        }
        @media (max-width: 450px) {
            td:nth-child(9), th:nth-child(9) {
                display: none;
            }
        }
        @media (max-width: 400px) {
            td:nth-child(5), th:nth-child(5) {
                display: none;
            }
        }
        @media (max-width: 330px) {
            td:nth-child(4), th:nth-child(4) {
                display: none;
            }
        }
    }
}
`;


export interface SliderWrapperProps {
    left?: number;
    right?: number;
    width?: number;
}

export const SliderWrapper = styled.div<SliderWrapperProps>`
    width: 100%;
    height: 18px;
    margin-top: 0.05em;
    background-color: ${({theme}) => theme === darkTheme ? '#D5D5D5' : 'white'};
    position: relative;

    > div {
        position: absolute;
        background-color: #000D44;
        border-top: 1px solid ${({theme}) => theme.body};
        border-bottom: 1px solid ${({theme}) => theme.body};
    }

    > div.bar {
        width: ${(props) => (props.width ? `${props.width * 100}%` : '100%')};
        height: 100%;
        left: ${(props) => (props.left ? `${props.left * 100}%` : '0')};
    }

    > div:not(.bar) {
        top: -2px;
        height: 22px;
        width: 2px;

    }

    > div.left-bound {
        left: ${(props) => (props.left ? `${props.left * 100}%` : '0')};
    }

    > div.right-bound {
        left: ${(props) => (props.right ? `${props.right * 100}%` : '0')};
    }
}
`;