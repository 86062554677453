import React, {useState, useEffect, useRef, ChangeEvent} from 'react';
import {SliderWrapper, SliderWrapperProps, StyledSlider} from "./utmb-gui.style";
import {RangeSliderProps, DistSliderProps} from "./interfaces";
import {dayOfYearToDateText} from "./functions";


const RangeSlider: React.FC<RangeSliderProps> = (
    {
        min,
        max,
        minValue,
        maxValue,
        step = 1,
        onMinChange,
        onMaxChange,
    }) => {
    const sliderBarRef = useRef<HTMLDivElement>(null);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [curHalf, setCurHalf] = useState<boolean | null>(null)
    const [forcedRerender, doForcedRerender] = useState<boolean>(false)

    const calculateSlideValues = (event: MouseEvent): number | undefined => {
        if (!sliderBarRef || sliderBarRef.current === null) {
            return undefined
        }
        const sliderBarRect = sliderBarRef.current.getBoundingClientRect();
        const sliderBarWidth = sliderBarRect.width;
        const offsetX = event.clientX - sliderBarRect.left;

        // Calculate the relative position of the cursor on the slider bar
        const relativePosition = offsetX / sliderBarWidth;

        // Calculate the new value based on the relative position
        const newValue = min + (max - min) * relativePosition;
        return newValue
    }

    const relativizeSliderValues = (lowValue: number, highValue: number): SliderWrapperProps => {
        const leftBound = (lowValue - min) / max;
        const rightBound = (highValue - min) / max;
        const sliderWidth = (highValue - lowValue) / max;
        return {left: leftBound, right: rightBound, width: sliderWidth}
    }

    const handleSliderChange = (event: MouseEvent) => {
        let newValue = calculateSlideValues(event)
        if (!newValue) {
            return
        }
        newValue = Math.round(newValue / step) * step

        let closestHalf = curHalf

        if (closestHalf === null) {
            const minDist = newValue - minValue;
            const maxDist = maxValue - newValue;
            closestHalf = minDist > maxDist
            setCurHalf(closestHalf)
        }
        if (closestHalf === false) {
            if (minValue != newValue && newValue >= min) {
                onMinChange(newValue)
                doForcedRerender(prev => !prev);
            }
        } else if (closestHalf === true) {
            if (maxValue != newValue && newValue <= max) {
                onMaxChange(newValue)
                doForcedRerender(prev => !prev);
            }
        }
    }

    useEffect(() => {
        const handleMouseUp = () => {
            setIsDragging(false);
            setCurHalf(null);
        };

        if (isDragging) {
            document.addEventListener('mousemove', handleSliderChange);
            document.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleSliderChange);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging, forcedRerender])

    const handleMouseDown = (event: any) => {
        setIsDragging(true);
        handleSliderChange(event)
    };

    const handleExit = () => {
        setCurHalf(null)
        setIsDragging(false)
    };
    return (
        <SliderWrapper
            ref={sliderBarRef}
            onMouseDown={handleMouseDown}
            onMouseUp={handleExit}
            onMouseLeave={handleExit}
            {...relativizeSliderValues(minValue, maxValue)}
        >
            <div className='bar'/>
            <div className='left-bound'/>
            <div className='right-bound'/>
        </SliderWrapper>
    )
}


export const RangeSelector: React.FC<DistSliderProps> = (
    {
        filter,
        min = 0,
        max = 250,
        step = 5,
        onChange = undefined,
        modifier = undefined,
    }) => {
    const [minValue, setMinValue] = useState(min);
    const [maxValue, setMaxValue] = useState(max);

    const handleMinInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = Math.min(Number(e.target.value), maxValue - 1);
        setMinValue(value);
        if (onChange != undefined) {
            onChange(filter, minValue, maxValue)
        }
    };

    const handleMaxInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = Math.max(Number(e.target.value), minValue + 1);
        setMaxValue(value);
        if (onChange != undefined) {
            onChange(filter, minValue, maxValue)
        }
    };

    const handleMinSliderChange = (newValue: number) => {
        const value = Math.min(newValue, maxValue - 1);
        setMinValue(value);
        if (onChange != undefined) {
            onChange(filter, minValue, maxValue)
        }
    };

    const handleMaxSliderChange = (newValue: number) => {
        const value = Math.max(newValue, minValue + 1);
        setMaxValue(value);
        if (onChange != undefined) {
            onChange(filter, minValue, maxValue)
        }
    };

    return (
        <StyledSlider className="distance-input-container">
            {modifier === undefined ? (
                <input
                    className="range-min"
                    type="number"
                    value={minValue}
                    onChange={handleMinInputChange}
                    step={step}
                    min={min}
                    max={max}
                />
            ) : (
                <p>{dayOfYearToDateText(minValue)}</p>
            )}
            <RangeSlider
                min={min}
                max={max}
                minValue={minValue}
                maxValue={maxValue}
                step={step}
                onMinChange={handleMinSliderChange}
                onMaxChange={handleMaxSliderChange}
            />
            {modifier === undefined ? (
                <input
                    className="range-max"
                    type="number"
                    value={maxValue}
                    onChange={handleMaxInputChange}
                    step={step}
                    min={min}
                    max={max}
                />
            ) : (
                <p>{dayOfYearToDateText(maxValue)}</p>
            )}
        </StyledSlider>
    );
};


export default RangeSelector