import {RaceMetadata} from "./interfaces";

export function dayOfYearToDateText(dayOfYear: number): string {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const daysInMonth = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    let day = dayOfYear;
    let month = 0;

    for (let i = 0; i < daysInMonth.length; i++) {
        if (day > daysInMonth[i]) {
            day -= daysInMonth[i];
            month++;
        } else {
            break;
        }
    }
    return `${monthNames[month]} ${day}`;
}


export const zip = (arrays: any[][]) => {
    return arrays[0].map((_, i) => arrays.map(array => array[i]));
};


export const float2hhmm = (hours: number): string => {
    // Covnverts hours as float to hh:mm format as string
    const hh = Math.floor(hours);
    const mm = Math.round((hours - hh) * 60);
    return `${hh.toString()}:${mm.toString().padStart(2, '0')}`;
};

export const titleCleaner = (title: string, year: number): string => {
    // Remove the year
    title = title.replace(year.toString(), '')
    // Replace "Ultra-Trail Du Mont-Blanc" with UTMB
    title = title.replace('Ultra-Trail Du Mont-Blanc', 'UTMB')
    return title
}

export const mostCommonString = (arr: string[]): string => {
    const titleCounts: { [key: string]: number } = {};

    let mostCommon = arr[-1];

    arr.forEach(title => {
        titleCounts[title] = (titleCounts[title] || 0) + 1;
        if (titleCounts[title] >= (titleCounts[mostCommon] || 0)) {
            mostCommon = title;
        }
    });
    return mostCommon;
};

export const float2perc = (float: number): string => {
    return (float * 100).toFixed(0) + '%';
}

export const percParticipants = (metaData: RaceMetadata, field: string, index: number): string => {
    if (metaData['N Participants'][index] === 0) {
        return '-'
    }
    // Get fraction
    const frac: number = (metaData[field][index] as number) / (metaData['N Participants'][index] as number);
    // Format as percentage
    const perc: string = float2perc(frac)
    return perc;
};


export const truncate = (text: string): string => {
    if (!text) {
        return text
    }
    if (text.length > 45) {
        text = text.slice(0, 37) + '...';
    }
    return text
}

export const calcMean = (values: number[]): number => {
    return values.reduce((acc, val) => acc + val, 0) / values.length
};