import {IndexData, RaceMetadata} from "./interfaces";

export const indexFallback: IndexData = {
    index: ['4', '8', '11', '12', '13', '14', '17', '18', '19', '23'],
    title: ['', '', '', '', '', '', '', '', '', ''],
    pretty_title: [
        'Addo Elephant Trail Run - 76 Km',
        'Chiemgauer 100 - 100 Mile',
        'THURINGEN ULTRA - THURINGEN ULTRA 100KM',
        'GORE-TEX Transalpine-Run - transalpine run',
        'Bogong to Hotham',
        'Glasshouse 100 - GLASSHOUSE - 100 miles',
        'THE GREAT NORTH WALK ULTRAs - 100 KILOMETRES',
        'THE GREAT NORTH WALK ULTRAs - 100 MILES',
        'Traunsee Bergmarathon - Rund Um Den Traunsee',
        'Olne Spa Olne'
    ],
    distance: [76, 160, 100, 264, 64, 159, 102, 167, 68, 69],
    elevation_gain: [2504, 7500, 2190, 15313, 3275, 2409, 3210, 5237, 4636, 2264],
    race_category: ['100K', '100M', '100K', '100M', '50K', '100M', '100K', '100M', '100K', '50K'],
    country: ['ZA', 'DE', 'DE', 'DE', 'AU', 'AU', 'AU', 'AU', 'AT', 'BE'],
    continent: ['', '', '', '', '', '', '', '', '', ''],
    dnf_rate: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    time: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    day: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
};


export const createMetaFallback = ({
    distance = 0,
    elevation = 0,
    last_time = 0,
    mean_time = 0,
    n_countries = 0,
    dnf_rate = 0,
    n_participants = 0,
    n_women = 0,
    race_category = '-',
    race_title = '',
    win_time = 0,
    year = 2022,
}: {
    distance?: number,
    elevation?: number,
    last_time?: number,
    mean_time?: number,
    n_countries?: number,
    dnf_rate?: number,
    n_participants?: number,
    n_women?: number,
    race_category?: string,
    race_title?: string,
    win_time?: number,
    year?: number,
}): RaceMetadata => ({
    "Distance": [distance],
    "Elevation Gain": [elevation],
    "Last Time": [last_time],
    "Mean Finish Time": [mean_time],
    "N Countries": [n_countries],
    "N DNF": [],
    "DNF R": [dnf_rate],
    "N Participants": [n_participants],
    "N Women": [n_women],
    "Race Category": [race_category],
    "Race Title": [race_title],
    "Winning Time": [win_time],
    "Year": [year],
});


export const metaFallback: RaceMetadata = {
    'Distance': [167.7, 169.6, 171, 171.2, 170, 171.1, 172.1, 170.3, 171],
    'Elevation Gain': [9618, 10060, 10225, 10170, 9990, 10210, 10055, 10050, 9963],
    'Last Time': [45.9886, 47.1431, 46.7111, 46.2564, 46.9475, 46.925, 47.7922, 47.1981, 48.2747],
    'Mean Finish Time': [39.7504, 39.9263, 40.3463, 39.2946, 39.91, 39.6884, 40.1348, 39.0281, 39.0403],
    'N Countries': [54, 57, 59, 71, 78, 79, 65, 75, 81],
    'N DNF': [0, 0, 0, 850, 782, 987, 827, 838, 936],
    'N Participants': [1582, 1631, 1468, 2537, 2561, 2543, 2347, 2627, 2693],
    'N Women': [114, 133, 130, 242, 264, 257, 186, 244, 317],
    'Race Category': ['100M', '100M', '100M', '100M', '100M', '100M', '100M', '100M', '100M'],
    'Race Title': ['UTMB®', 'UTMB®', 'UTMB®', 'UTMB®', 'UTMB®', 'UTMB®', 'UTMB®', 'UTMB®', 'UTMB®'],
    'Winning Time': [20.1956, 21.1542, 22.0006, 19.0317, 20.7378, 20.3186, 20.7664, 19.825, 19.6286],
    'Year': [2014, 2015, 2016, 2017, 2018, 2019, 2021, 2022, 2023],
};


export const histFallback: Array<number[]> =
    [
        [
            19.032,
            19.617,
            19.909,
            20.201,
            20.494,
            20.786,
            21.079,
            21.371,
            21.664,
            21.956,
            22.248,
            22.541,
            22.833,
            23.126,
            23.418,
            23.711,
            24.003,
            24.295,
            24.588,
            24.88,
            25.173,
            25.465,
            25.758,
            26.05,
            26.342,
            26.635,
            26.927,
            27.22,
            27.512,
            27.805,
            28.097,
            28.389,
            28.682,
            28.974,
            29.267,
            29.559,
            29.852,
            30.144,
            30.436,
            30.729,
            31.021,
            31.314,
            31.606,
            31.899,
            32.191,
            32.483,
            32.776,
            33.068,
            33.361,
            33.653,
            33.946,
            34.238,
            34.53,
            34.823,
            35.115,
            35.408,
            35.7,
            35.993,
            36.285,
            36.578,
            36.87,
            37.162,
            37.455,
            37.747,
            38.04,
            38.332,
            38.625,
            38.917,
            39.209,
            39.502,
            39.794,
            40.087,
            40.379,
            40.672,
            40.964,
            41.256,
            41.549,
            41.841,
            42.134,
            42.426,
            42.719,
            43.011,
            43.303,
            43.596,
            43.888,
            44.181,
            44.473,
            44.766,
            45.058,
            45.35,
            45.643,
            45.935,
            46.228,
            46.52,
            46.813,
            47.105,
            47.397,
            47.69,
            47.982
        ],
    [
        0,
    0,
    1,
    0,
    0,
    2,
    0,
    1,
    1,
    0,
    0,
    2,
    3,
    4,
    0,
    5,
    2,
    3,
    3,
    4,
    4,
    3,
    3,
    3,
    4,
    7,
    2,
    3,
    3,
    5,
    8,
    6,
    2,
    4,
    6,
    11,
    8,
    5,
    6,
    9,
    7,
    6,
    13,
    7,
    7,
    16,
    15,
    17,
    6,
    12,
    11,
    19,
    9,
    22,
    9,
    14,
    20,
    9,
    14,
    14,
    16,
    21,
    19,
    21,
    19,
    35,
    48,
    26,
    43,
    41,
    22,
    23,
    40,
    28,
    24,
    23,
    47,
    35,
    40,
    31,
    36,
    40,
    54,
    41,
    56,
    47,
    53,
    73,
    91,
    67,
    38,
    4,
    0,
    0,
    0,
    0,
    0,
    0,
    0
],
[
    0,
    0,
    0,
    0,
    0,
    0,
    1,
    0,
    1,
    2,
    1,
    1,
    1,
    1,
    0,
    1,
    1,
    2,
    4,
    2,
    1,
    3,
    3,
    3,
    2,
    4,
    3,
    3,
    8,
    3,
    6,
    5,
    3,
    5,
    13,
    11,
    8,
    11,
    10,
    5,
    6,
    9,
    13,
    10,
    16,
    12,
    16,
    8,
    14,
    4,
    11,
    22,
    15,
    15,
    11,
    15,
    17,
    8,
    17,
    17,
    22,
    30,
    19,
    25,
    26,
    27,
    34,
    31,
    41,
    53,
    31,
    22,
    21,
    33,
    37,
    37,
    40,
    40,
    30,
    34,
    41,
    45,
    38,
    44,
    45,
    33,
    45,
    58,
    53,
    60,
    68,
    57,
    16,
    8,
    2,
    1,
    0,
    0,
    0
],
    [
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        1,
        2,
        2,
        1,
        2,
        2,
        3,
        0,
        2,
        1,
        2,
        3,
        2,
        2,
        2,
        2,
        4,
        5,
        0,
        4,
        1,
        7,
        2,
        4,
        6,
        8,
        4,
        5,
        3,
        8,
        7,
        3,
        3,
        7,
        8,
        12,
        5,
        12,
        7,
        7,
        15,
        7,
        5,
        8,
        15,
        15,
        13,
        12,
        17,
        24,
        13,
        14,
        24,
        25,
        19,
        24,
        22,
        24,
        29,
        22,
        40,
        40,
        22,
        31,
        22,
        26,
        32,
        44,
        42,
        36,
        39,
        31,
        32,
        47,
        56,
        44,
        40,
        52,
        40,
        51,
        54,
        62,
        45,
        27,
        5,
        0,
        0,
        0,
        0,
        0
    ],
    [
        2,
        1,
        2,
        2,
        0,
        0,
        0,
        2,
        2,
        2,
        2,
        3,
        2,
        1,
        4,
        4,
        1,
        3,
        1,
        1,
        2,
        3,
        7,
        3,
        2,
        5,
        3,
        6,
        6,
        9,
        11,
        2,
        11,
        10,
        3,
        6,
        7,
        10,
        13,
        5,
        13,
        9,
        20,
        22,
        12,
        11,
        12,
        8,
        14,
        14,
        20,
        13,
        19,
        15,
        14,
        13,
        15,
        10,
        28,
        21,
        30,
        15,
        19,
        25,
        30,
        26,
        26,
        25,
        29,
        40,
        31,
        31,
        37,
        30,
        40,
        34,
        50,
        47,
        43,
        37,
        38,
        54,
        52,
        50,
        57,
        60,
        52,
        77,
        63,
        42,
        23,
        10,
        2,
        0,
        0,
        0,
        0,
        0,
        0
    ],
    [
        0,
        0,
        0,
        0,
        1,
        0,
        0,
        1,
        1,
        1,
        0,
        2,
        1,
        2,
        2,
        3,
        2,
        4,
        2,
        2,
        1,
        5,
        2,
        7,
        1,
        5,
        6,
        9,
        9,
        2,
        8,
        9,
        7,
        6,
        11,
        7,
        5,
        9,
        7,
        6,
        4,
        13,
        17,
        17,
        11,
        6,
        14,
        8,
        22,
        15,
        12,
        12,
        17,
        22,
        13,
        17,
        23,
        22,
        15,
        23,
        9,
        25,
        15,
        37,
        27,
        26,
        24,
        28,
        40,
        33,
        47,
        28,
        29,
        34,
        41,
        35,
        39,
        43,
        46,
        37,
        44,
        46,
        44,
        50,
        45,
        38,
        45,
        70,
        50,
        77,
        83,
        61,
        27,
        4,
        3,
        0,
        0,
        0,
        0
    ],
    [
        0,
        0,
        0,
        1,
        0,
        0,
        1,
        0,
        1,
        1,
        0,
        3,
        1,
        3,
        2,
        1,
        2,
        5,
        2,
        4,
        0,
        7,
        5,
        5,
        3,
        3,
        0,
        12,
        4,
        3,
        2,
        6,
        6,
        6,
        11,
        7,
        5,
        11,
        9,
        10,
        4,
        12,
        15,
        5,
        9,
        8,
        18,
        6,
        16,
        15,
        7,
        10,
        21,
        11,
        18,
        10,
        29,
        10,
        20,
        21,
        25,
        21,
        30,
        28,
        25,
        25,
        22,
        21,
        23,
        20,
        21,
        17,
        38,
        27,
        32,
        30,
        29,
        47,
        47,
        41,
        37,
        45,
        37,
        33,
        39,
        64,
        62,
        44,
        59,
        49,
        35,
        43,
        27,
        5,
        1,
        0,
        0,
        0,
        0
    ],
    [
        0,
        0,
        0,
        0,
        1,
        1,
        1,
        1,
        1,
        0,
        2,
        0,
        2,
        2,
        1,
        3,
        2,
        1,
        3,
        3,
        4,
        2,
        5,
        3,
        2,
        6,
        1,
        3,
        6,
        4,
        10,
        4,
        6,
        7,
        3,
        4,
        3,
        7,
        9,
        5,
        8,
        11,
        6,
        12,
        11,
        9,
        6,
        10,
        9,
        16,
        14,
        20,
        12,
        15,
        10,
        13,
        14,
        17,
        14,
        10,
        30,
        19,
        16,
        31,
        22,
        23,
        27,
        22,
        29,
        31,
        26,
        22,
        19,
        21,
        26,
        27,
        31,
        38,
        30,
        45,
        39,
        30,
        38,
        46,
        49,
        38,
        44,
        42,
        48,
        80,
        53,
        64,
        30,
        16,
        6,
        4,
        2,
        1,
        0
    ],
    [
        0,
        1,
        1,
        0,
        1,
        0,
        1,
        3,
        1,
        2,
        3,
        2,
        5,
        2,
        4,
        5,
        6,
        3,
        5,
        4,
        4,
        1,
        2,
        4,
        7,
        5,
        1,
        8,
        8,
        5,
        6,
        11,
        12,
        12,
        12,
        10,
        9,
        12,
        11,
        10,
        9,
        21,
        18,
        10,
        19,
        13,
        11,
        14,
        16,
        20,
        15,
        20,
        16,
        28,
        23,
        15,
        23,
        11,
        20,
        19,
        18,
        24,
        34,
        18,
        41,
        32,
        33,
        27,
        31,
        46,
        38,
        33,
        28,
        41,
        35,
        40,
        47,
        41,
        28,
        30,
        31,
        27,
        33,
        37,
        41,
        63,
        52,
        43,
        65,
        51,
        59,
        56,
        24,
        1,
        0,
        1,
        0,
        0,
        0
    ],
    [
        0,
        1,
        2,
        0,
        0,
        2,
        3,
        2,
        2,
        3,
        1,
        3,
        2,
        3,
        2,
        3,
        2,
        1,
        4,
        5,
        4,
        3,
        5,
        8,
        5,
        5,
        9,
        10,
        8,
        9,
        6,
        8,
        10,
        10,
        13,
        13,
        6,
        2,
        12,
        5,
        10,
        18,
        19,
        17,
        13,
        10,
        18,
        20,
        13,
        22,
        13,
        30,
        19,
        16,
        11,
        23,
        31,
        11,
        32,
        18,
        19,
        26,
        24,
        18,
        29,
        24,
        35,
        34,
        30,
        33,
        29,
        32,
        31,
        35,
        34,
        30,
        31,
        36,
        29,
        32,
        42,
        43,
        41,
        45,
        50,
        36,
        35,
        46,
        57,
        60,
        39,
        56,
        47,
        5,
        3,
        0,
        4,
        0,
        1
    ]
]