import {FilterProps} from "./interfaces";
import {countryCodesToNames} from "./utmb-gui-utils";

class Filter {
    private type: string;
    private column: number[] | string[];
    public boolIdx: boolean[];

    private category_value: string[] = [];
    private category_state: boolean[] = [];

    private min: number = 0;
    private max: number = 250;

    private text: string = '';

    constructor(data: FilterProps) {
        this.type = data.type;
        this.column = data.column;
        this.boolIdx = Array(this.column.length).fill(true);

        if (this.type === 'categorical') {
            if (!data.categories) {
                throw new Error("Categories must be provided for categorical filter");
            } else {
                this.category_value = data.categories;
                this.category_state = Array(data.categories.length).fill(true);
            }
        } else if (this.type === 'numeric') {
            if (!Array.isArray(this.column) || this.column.length === 0 || typeof this.column[0] !== 'number') {
                throw new Error("Numeric filter requires a column of numbers");
            }
            this.min = Math.min(...(this.column as number[]));
            this.max = Math.max(...(this.column as number[]));
        } else if (this.type === 'text') {
            this.text = ''
            if (data.isCountry) {
                this.column = data.column.map(value => countryCodesToNames[value].toLowerCase());
            }
        }
    }

    public sort = (key: number[]): void => {
        this.boolIdx = this.boolIdx.sort((a, b) => key.indexOf(a) - key.indexOf(b));
    }

    public toggle = (index: number) => {
        if (this.category_state !== undefined) {
            this.category_state[index] = !this.category_state[index]
            this.updateBoolIdx()
        }
    }

    public limit = (min: number, max: number) => {
        this.min = min;
        this.max = max;
        this.updateBoolIdx()
    }

    public provide = (text: string) => {
        this.text = text;
        this.updateBoolIdx();
    }

    private updateBoolIdx = () => {
        if (this.type === 'categorical') {
            const allowedStates = this.category_value.filter((_, index) => this.category_state[index]);
            this.boolIdx = this.column.map(value => allowedStates.includes(value));
        } else if (this.type === 'numeric') {
            this.boolIdx = this.column.map(value => (value >= this.min && value <= this.max));
        } else if (this.type === 'text') {
            this.boolIdx = this.column.map(value => value.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")));
        }
    }

    public categoryState = (index: number): boolean => {
        if (this.category_state !== undefined) {
            return this.category_state[index]
        } else {
            return false
        }
    }
}

export default Filter