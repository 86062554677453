import React, {useState, useEffect, ChangeEvent, useRef} from 'react';
import {FilterCheckbox} from "./components";
import {categoryTypes, sortingType, IndexData, RaceMetadata, ToggleChevronProps} from "./interfaces";
import RangeSelector from "./rangeSlider";
import {indexFallback, metaFallback, histFallback, createMetaFallback} from "./fallbackData"
import {dayOfYearToDateText, truncate} from "./functions"
import Filter from "./filterClass";
import {countryCodeToFlag} from "./utmb-gui-utils";
import {StyledExcuse, StyledUtmbGuiWrapper} from "./utmb-gui.style";
import UtmbInfoPanel from "./infopanel";
import {fetchJsonData} from "../utils";
import UtmbIcon from "./icons";
import {
    FaSortDown,
    FaSortUp,
    FaChevronLeft,
    FaChevronRight,
    FaChevronDown,
    FaChevronUp,
} from 'react-icons/fa6';


const metaRoot = 'https://meta.maartenpoirot.com/utmb-world/data/'
const slowMetaRoot = 'https://s3.eu-west-1.amazonaws.com/maartenpoirot.com-site-interactions/utmb-world/data/'

const raceCategories: categoryTypes[] = ['100M', '100K', '50K', '20K', '-']
const continents = ['Europe', 'North America', 'South America', 'Asia', 'Africa', 'Oceania']

const sortingOptions = ['UID', 'Title', 'Distance', 'Elevation gain', 'DNF %']
const sortingFields: sortingType[] = ['index', 'pretty_title', 'distance', 'elevation_gain', 'dnf_rate']

const continent_codes = ['EU', 'NA', 'SA', 'AS', 'AF', 'OC']


const sortIndex = (data: IndexData, sortingColumn: sortingType = 'index', reverse: boolean = false): IndexData => {
    // Copy the original data to avoid mutating it
    const sortedData: IndexData = {...data};

    // Get a list of indices
    const indices = sortedData['index'].map((_, idx: number) => idx);

    // Get the sorting column data
    let sortingData: Array<number | string> = sortedData[sortingColumn];

    // Convert the index to integers
    if (sortingColumn === 'index') {
        sortingData = sortingData.map((value: string) => parseInt(value, 10));
        indices.sort((a: number, b: number) => sortingData[a] - sortingData[b]);
    } else if (sortingColumn === 'title' || sortingColumn === 'pretty_title') {
        indices.sort((a: number, b: number) => sortingData[a].localeCompare(sortingData[b], 'en', {sensitivity: 'base'}));
    } else {
        indices.sort((a: number, b: number) => sortingData[a] - sortingData[b]);
    }

    if (reverse) {
        indices.reverse();
    }
    // Reorder all columns based on the sorted indices
    Object.keys(sortedData).forEach(key => {
        sortedData[key] = indices.map(idx => sortedData[key][idx]);
    });
    return sortedData;
};


const ApplyFilters = (indexData: IndexData, filters: any[any | null] = []) => {
    // Initialize masterBoolIdx to true for all indexes
    const length = indexData.index.length;
    const masterBoolIdx = Array(length).fill(true);

    // Perform logical AND operation on all filters' boolIdx arrays
    filters.forEach((filter: { current: { boolIdx: boolean[]; }; } | null) => {
        if (filter === null) {
            return
        }
        filter.current?.boolIdx.forEach((value: boolean, idx: number) => {
            masterBoolIdx[idx] = masterBoolIdx[idx] && value;
        });
    });

    // Filter indexData based on masterBoolIdx
    const filteredIndex: IndexData = {};
    for (const key in indexData) {
        if (indexData.hasOwnProperty(key)) {
            filteredIndex[key] = indexData[key].filter((_, idx) => masterBoolIdx[idx]);
        }
    }
    return filteredIndex;
}


const UtmbGui = () => {
    const dataIndex = useRef<IndexData>(indexFallback);
    const [filteredIndex, setFilteredIndex] = useState<IndexData>(indexFallback)
    const [histData, setHistData] = useState<number[][]>(histFallback);
    const [metaData, setMetaData] = useState<RaceMetadata | null>(metaFallback)
    const [raceUid, setRaceUid] = useState<string>('142');
    const [sortField, setSortField] = useState<number>(0)
    const [yearFilter, setYearFilter] = useState<boolean[]>(Array(10).fill(true))
    const [filterCollapseState, setFilterCollapseState] = useState<boolean[]>([true, false, false, false, false, false, false, false, false])

    const continentFilter = useRef<any>(null);
    const categoryFilter = useRef<any>(null);
    const elevationFilter = useRef<any>(null);
    const dateFilter = useRef<any>(null);
    const distanceFilter = useRef<any>(null);
    const dnfFilter = useRef<any>(null);
    const titleFilter = useRef<any>(null);
    const countryFilter = useRef<any>(null);

    // const indexFilter = useRef<any>(null);
    const [doneFilter, doFilter] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState<number>(0)
    const nRows = 10;
    const [raceName, setRaceName] = useState<string>('');
    const [raceCountry, setRaceCountry] = useState<string>('');
    const [reverseSort, setReverseSort] = useState<boolean>(true)
    const countyCode = useRef<string>('FR')

    const anchorRef = useRef<HTMLHeadingElement>(null);

    useEffect(() => {
        fetchJsonData(`${metaRoot}index.json`).then((loadedIndex => {
            dataIndex.current = loadedIndex
            setFilteredIndex(loadedIndex)
            continentFilter.current = new Filter({
                type: 'categorical',
                column: loadedIndex['continent'],
                categories: continent_codes,
            })
            categoryFilter.current = new Filter({
                type: 'categorical',
                column: loadedIndex['race_category'],
                categories: raceCategories,
            })
            distanceFilter.current = new Filter({
                type: 'numeric',
                column: loadedIndex['distance'],
                min: 1,
                max: 250,
            })
            elevationFilter.current = new Filter({
                type: 'numeric',
                column: loadedIndex['elevation_gain'],
                min: 1,
                max: 10000,
            })
            dnfFilter.current = new Filter({
                type: 'numeric',
                column: loadedIndex['dnf_rate'],
                min: 0,
                max: 100,
            })
            titleFilter.current = new Filter({
                type: 'text',
                column: loadedIndex['pretty_title'],
            })
            countryFilter.current = new Filter({
                type: 'text',
                column: loadedIndex['country'],
                isCountry: true,
            })
            dateFilter.current = new Filter({
                type: 'numeric',
                column: loadedIndex['day'],
                min: 0,
                max: 366,
            })
        }))
    }, []);

    useEffect(() => {
        const sortedIndex = sortIndex(dataIndex.current, sortingFields[sortField], reverseSort)
        dataIndex.current = sortedIndex
        doFilter(prev => !prev)
    }, [sortField, reverseSort])

    const handleRacePick = (race_uid: string) => {
        if (race_uid === undefined || (race_uid == raceUid && histData.length > 0)) {
            return
        }
        fetchJsonData(`${metaRoot}uid-${race_uid}_meta.json`).then(r => {
            if (r !== null) {
                setMetaData(r)
            } else {
                fetchJsonData(`${slowMetaRoot}uid-${race_uid}_meta.json`).then(r => {
                    if (r !== null) {
                        setMetaData(r)
                    } else {
                        console.log(`${slowMetaRoot}uid-${race_uid}_meta.json`)
                    }
                })
            }
        });
        console.log(metaData['DNF R'] == 100)
        if (metaData['DNF R'] != 100) {
            fetchJsonData(`${metaRoot}uid-${race_uid}_hist.json`).then(r => {
                if (r !== null) {
                    setHistData(r)
                } else {
                    fetchJsonData(`${slowMetaRoot}uid-${race_uid}_hist.json`, setHistData, []).then()
                }
            })
        } else {
            setHistData([])
        }


        // indexFilter.current.choose(race_uid)
        countyCode.current = filteredIndex['country'][filteredIndex['index'].indexOf(race_uid.toString())]

        // Reset year filters
        setYearFilter(Array(10).fill(true)) // Length should be > No. Years

        // Construct meta placeholder from index
        const entryIndex = dataIndex.current['index'].indexOf(race_uid)

        const metaFallback = createMetaFallback({
            distance: dataIndex.current['distance'][entryIndex],
            elevation: dataIndex.current['elevation_gain'][entryIndex],
            mean_time: dataIndex.current['time'][entryIndex],
            dnf_rate: dataIndex.current['dnf_rate'][entryIndex],
            race_category: dataIndex.current['race_category'][entryIndex],
            race_title: dataIndex.current['pretty_title'][entryIndex],
        })

        setMetaData(metaFallback);
        setRaceUid(race_uid)

        //  Scroll into view
        if (anchorRef.current) {
            anchorRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    }

    const handleCheckFilterChange = (filter: any, index: number) => {
        filter.current.toggle(index)
        doFilter(prev => !prev)
    }

    const UtmbSearchResults: React.FC<IndexData> = (
        {
            index = [],
            pretty_title = 'None',
            race_category = '-',
            distance = [],
            elevation_gain = [],
            country = [],
        }) => {

        const nPages = Math.ceil(index.length / nRows)

        const handlePaging = (increment: number | null, goTo: number | null): void => {
            if (increment != null) {
                if (currentPage + increment < nPages && currentPage + increment >= 0) {
                    setCurrentPage(prev => prev + increment);
                }
            } else if (goTo != null) {
                setCurrentPage(goTo)
            }
        }
        return (
            <>
                <table className='search-results'>
                    <thead>
                    <tr className='table-heading'>
                        {/*<th className='search-index'>Index</th>*/}
                        <th className='search-title'>Title</th>
                        <th className='search-category'>Category</th>
                        <th className='search-distance'>Distance</th>
                        <th className='search-elevation'>Elevation</th>
                        <th className='search-location'>Location</th>
                    </tr>
                    </thead>
                    <tbody>
                    {index.slice(0, nRows).map((_, i) => {
                        i += currentPage * nRows;
                        return (
                            <tr key={i} onClick={() => handleRacePick(index[i])}>
                                {/*<td className='search-index'>{index[i]}</td>*/}
                                <td className='search-title'>{truncate(pretty_title[i])}</td>
                                <td className='search-category'><UtmbIcon category={race_category[i]}/></td>
                                <td className='search-distance'>{distance[i]}</td>
                                <td className='search-elevation'>{elevation_gain[i]}</td>
                                <td className='search-location'>{countryCodeToFlag(country[i])}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                <div className='page-buttons'>
                    {nPages > 1 && (
                        <button className='button-previous' onClick={() => handlePaging(-1, null)}><FaChevronLeft
                            className='button'/></button>)}
                    {nPages > 2 && (<button className={`button-1${currentPage == 0 ? ' underscore' : ''}`}
                                            onClick={() => handlePaging(null, 0)}>{1}</button>)}
                    {nPages > 3 && (<button className={`button-2${currentPage == 1 ? ' underscore' : ''}`}
                                            onClick={() => handlePaging(null, 1)}>{2}</button>)}
                    {nPages > 4 && (<button className={`button-3${currentPage == 2 ? ' underscore' : ''}`}
                                            onClick={() => handlePaging(null, 2)}>{3}</button>)}
                    {/*<span>Page {currentPage + 1}</span>*/}
                    <button>{nPages === 1 ? 1 : '...'}</button>
                    {nPages > 4 && (<button className={`button-8${currentPage == nPages - 3 ? ' underscore' : ''}`}
                                            onClick={() => handlePaging(null, nPages - 3)}>{nPages - 2}</button>)}
                    {nPages > 3 && (<button className={`button-9${currentPage == nPages - 2 ? ' underscore' : ''}`}
                                            onClick={() => handlePaging(null, nPages - 2)}>{nPages - 1}</button>)}
                    {nPages > 2 && (<button className={`button-10${currentPage == nPages - 1 ? ' underscore' : ''}`}
                                            onClick={() => handlePaging(null, nPages - 1)}>{nPages}</button>)}
                    {nPages > 1 && (
                        <button className='button-next' onClick={() => handlePaging(1, null)}><FaChevronRight
                            className='button'/></button>)}
                </div>
            </>
        );
    }

    const handleSliderFilterChange = (filter: any, min: number, max: number) => {
        // const handleSliderFilterChange = (min: number, max: number) => {
        filter.current.limit(min, max)
        doFilter(prev => !prev)
    };

    const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedIndex = e.target.selectedIndex;
        setSortField(selectedIndex)
    };

    const handleReverse = () => {
        setReverseSort(!reverseSort)
    }

    const handleTextFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
        let text = e.target.value
        if (e.target.id === 'pretty_title') {
            setRaceName(text);
            titleFilter.current.provide(text.toLowerCase())
        } else if (e.target.id === 'country') {
            setRaceCountry(text);
            countryFilter.current.provide(text.toLowerCase())
        } else {
            console.log('Unrecognized text field ID')
        }
    };


    useEffect(() => {
        const filters = [
            titleFilter,
            countryFilter,
            null,
            distanceFilter,
            elevationFilter,
            dateFilter,
            dnfFilter,
            categoryFilter,
            continentFilter,
        ].filter((_, index) => filterCollapseState[index]);
        console.log(filters)
        setFilteredIndex(ApplyFilters(dataIndex.current, filters))
    }, [doneFilter, raceName, raceCountry, filterCollapseState]);

    const collapseFilter = (index: number) => {
        const newCollapseState = {...filterCollapseState}
        newCollapseState[index] = !newCollapseState[index]
        setFilterCollapseState(newCollapseState)
    }

    const ToggleChevron: React.FC<ToggleChevronProps> = ({filterCollapseState, keyNumber}) => {
        const isCollapsed = filterCollapseState[keyNumber];
        return (
            <span className={`${isCollapsed ? '' : 'un'}checked`}>
                {isCollapsed ? (
                    <FaChevronDown onClick={() => collapseFilter(keyNumber)} className='collapse'/>
                ) : (
                    <FaChevronUp onClick={() => collapseFilter(keyNumber)} className='collapse'/>
                )}
            </span>
        );
    };

    return (
        <StyledUtmbGuiWrapper>
            <div className="utmb-search-box">
                <h1>UTMB Race Finder</h1>
                <form>
                    <fieldset className={filterCollapseState[0] ? '' : 'collapsed'}>
                        <legend>Title</legend>
                        <ToggleChevron keyNumber={0} filterCollapseState={filterCollapseState}/>
                        {filterCollapseState[0] && (
                            <input type="text" id="pretty_title" value={raceName} onChange={handleTextFilterChange}/>)}
                    </fieldset>
                    <fieldset className={filterCollapseState[1] ? '' : 'collapsed'}>
                        <legend>Country</legend>
                        <ToggleChevron keyNumber={1} filterCollapseState={filterCollapseState}/>
                        {filterCollapseState[1] && (
                            <input type="text" id="country" value={raceCountry} onChange={handleTextFilterChange}/>)}
                    </fieldset>
                    <fieldset className={filterCollapseState[2] ? '' : 'collapsed'}>
                        <legend>Sort By</legend>
                        <ToggleChevron keyNumber={2} filterCollapseState={filterCollapseState}/>
                        {filterCollapseState[2] && (<>
                            <FaSortUp onClick={handleReverse} style={{display: reverseSort ? 'none' : 'inline'}}/>
                            <FaSortDown onClick={handleReverse} style={{display: reverseSort ? 'inline' : 'none'}}/>
                            <select id="sorting-options" onChange={handleSortChange}>
                                {sortingOptions.map((option, index) => (
                                    <option key={index} value={option}>{option}</option>
                                ))}
                            </select>
                        </>)}
                    </fieldset>
                    <fieldset className={filterCollapseState[3] ? '' : 'collapsed'}>
                        <legend>Distance (km)</legend>
                        <ToggleChevron keyNumber={3} filterCollapseState={filterCollapseState}/>
                        {filterCollapseState[3] && (<RangeSelector
                            filter={distanceFilter}
                            onChange={handleSliderFilterChange}
                            min={0}
                            max={250}
                            step={5}
                        />)}
                    </fieldset>
                    <fieldset className={filterCollapseState[4] ? '' : 'collapsed'}>
                        <legend>Elevation Gain (m)</legend>
                        <ToggleChevron keyNumber={4} filterCollapseState={filterCollapseState}/>
                        {filterCollapseState[4] && (<RangeSelector
                            filter={elevationFilter}
                            onChange={handleSliderFilterChange}
                            min={0}
                            max={15000}
                            step={200}
                        />)}
                    </fieldset>
                    <fieldset className={filterCollapseState[5] ? '' : 'collapsed'}>
                        <legend>Date</legend>
                        <ToggleChevron keyNumber={5} filterCollapseState={filterCollapseState}/>
                        {filterCollapseState[5] && (<RangeSelector
                            filter={dateFilter}
                            onChange={handleSliderFilterChange}
                            min={1}
                            max={366}
                            step={7}
                            modifier={dayOfYearToDateText}
                        />)}
                    </fieldset>
                    <fieldset className={filterCollapseState[6] ? '' : 'collapsed'}>
                        <legend>DNF (%)</legend>
                        <ToggleChevron keyNumber={6} filterCollapseState={filterCollapseState}/>
                        {filterCollapseState[6] && (<RangeSelector
                            filter={dnfFilter}
                            onChange={handleSliderFilterChange}
                            min={0}
                            max={100}
                            step={5}
                        />)}
                    </fieldset>
                    <fieldset className={`race-category ${filterCollapseState[7] ? '' : 'collapsed'}`}>
                        <legend>Race Category</legend>
                        <ToggleChevron keyNumber={7} filterCollapseState={filterCollapseState}/>

                        {filterCollapseState[7] && (raceCategories.map((category, index) => (
                            <label key={category}>
                                <FilterCheckbox
                                    checked={categoryFilter.current ? categoryFilter.current.categoryState(index) : true}
                                    onClick={() => handleCheckFilterChange(categoryFilter, index)}
                                />
                                <UtmbIcon
                                    category={category}
                                    style={{height: '18px'}}
                                />
                            </label>
                        )))}
                    </fieldset>
                    <fieldset className={`race-continent ${filterCollapseState[8] ? '' : 'collapsed'}`}>
                        <legend>Continent</legend>
                        <ToggleChevron keyNumber={8} filterCollapseState={filterCollapseState}/>

                        {filterCollapseState[8] && (continents.map((category, index) => (
                            <label key={category}>
                                <FilterCheckbox
                                    checked={categoryFilter.current ? continentFilter.current.categoryState(index) : true}
                                    onClick={() => handleCheckFilterChange(continentFilter, index)}
                                />
                                {category}
                            </label>
                        )))}
                    </fieldset>
                </form>
            </div>
            <h1>Matching Races</h1>
            <div>
                <UtmbSearchResults {...filteredIndex}/>
            </div>
            {metaData ? (
                <UtmbInfoPanel
                    yearFilter={yearFilter}
                    setYearFilter={setYearFilter}
                    raceUid={raceUid}
                    histData={histData}
                    metaData={metaData}
                    countryCode={countyCode.current}
                    anchorRef={anchorRef}
                />
            ) : (
                <StyledExcuse>Error Loading metadata. Please try again</StyledExcuse>
            )}

        </StyledUtmbGuiWrapper>
    )
};

export default UtmbGui;
