import React from "react";
import styled from 'styled-components';


const Icon20K = () => {
    return (
        <>
            <polygon fill="#000D44"
                     points="547.833,204 593.791,204 610.833,121.5 657.25,218.625 702.666,116.584 725.083,203.875 772.771,203.938 714.167,0 657.666,120.333 595.083,12.25"/>
            <g>
                <polygon fill="#FFFF00" points="563.12,17.505 517.286,203.88 99.65,203.672 99.733,17.505"/>
                <path fill="#000D44"
                      d="M251.082,138.61v24.634h-90.663l46.08-49.714c6.583-7.124,12.867-17.214,12.867-27.454 c0-7.123-5.087-14.544-12.867-14.544c-7.929,0-12.268,6.085-12.268,13.652c0,1.484,0.15,3.118,0.449,4.75h-32.315 c0.897-25.674,17.354-43.038,43.985-43.038c23.638,0,43.536,15.436,43.536,39.772c0,16.028-6.732,25.377-18.252,35.765 l-17.504,16.177H251.082z"/>
                <path fill="#000D44"
                      d="M357.785,107.296c0,29.087-14.243,60.401-47.976,60.401c-33.733,0-47.976-31.314-47.976-60.401 c0-29.088,14.393-60.399,47.976-60.399S357.785,78.208,357.785,107.296z M291.068,107.148c0,10.834,3.598,34.875,18.741,34.875 c15.142,0,18.74-24.041,18.74-34.875c0-10.832-3.598-34.577-18.74-34.577C294.667,72.571,291.068,96.316,291.068,107.148z"/>
                <path fill="#000D44"
                      d="M436.145,51.348h36.185l-44.674,52.982l48.694,58.914h-37.824l-37.674-48.526h-0.299v48.526h-29.187V51.348 h29.187v46.155h0.299L436.145,51.348z"/>
            </g>
            <polygon fill="#5D5D5D" points="560.501,673.499 514.667,859.874 97.833,859.666 97.916,673.499"/>
        </>
    );
};

const Icon50K = () => {
    return (
        <>
            <polygon fill="#000D44" points="547.833,204 593.791,204 610.833,121.5 657.25,218.625 702.666,116.584 725.083,203.875
	772.771,203.938 714.167,0 657.666,120.333 595.083,12.25 "/>
            <g>
                <polygon fill="#FC5A00" points="563.167,17.505 517.333,203.88 113.833,203.672 113.916,17.505 	"/>
                <path fill="#000D44" d="M213.079,79.53l-1.896,12.764l9.042-0.743c22.459-1.778,38.94,16.919,38.94,40.069
		c0,11.724-5.104,22.706-14.146,30.274c-8.75,7.123-21.147,9.349-32.085,9.349c-12.542,0-25.231-3.117-35.877-10.09l4.229-27.454
		c8.459,7.716,19.251,12.908,30.627,12.908c9.042,0,17.501-6.677,17.501-16.472c0-13.505-12.688-19.143-24.064-19.143
		c-7.292,0-14.438,1.483-21.001,4.599l9.625-60.696h57.17V79.53H213.079z"/>
                <path fill="#000D44" d="M366.572,110.844c0,29.087-13.914,60.399-46.869,60.399s-46.87-31.313-46.87-60.399
		c0-29.088,14.061-60.399,46.87-60.399C352.511,50.444,366.572,81.756,366.572,110.844z M301.394,110.696
		c0,10.834,3.515,34.874,18.309,34.874s18.309-24.04,18.309-34.874s-3.515-34.577-18.309-34.577S301.394,99.862,301.394,110.696z"/>
                <path fill="#000D44" d="M443.316,53.818h36.029l-44.481,52.98l48.484,58.916h-37.661l-37.513-48.527h-0.297v48.527h-29.061V53.818
		h29.061v46.152h0.297L443.316,53.818z"/>
            </g>
            <polygon fill="#5D5D5D" points="560.501,673.499 514.667,859.874 97.833,859.666 97.916,673.499 "/>
        </>
    );
};

const Icon100K = () => {
    return (
        <>
            <polygon fill="#000D44" points="547.833,204 593.791,204 610.833,121.5 657.25,218.625 702.666,116.584 725.083,203.875
	772.771,203.938 714.167,0 657.666,120.333 595.083,12.25 "/>
            <g>
                <polygon fill="#7DDF64" points="563.125,17.5 517.291,203.875 29.833,203.667 29.916,17.5 	"/>
                <path fill="#000D44" d="M84.333,75.828V51.193h45.476V163.09h-29.32V75.828H84.333z"/>
                <path fill="#000D44" d="M247.553,107.141c0,29.088-14.283,60.4-48.11,60.4c-33.828,0-48.11-31.313-48.11-60.4
		c0-29.087,14.433-60.398,48.11-60.398C233.12,46.742,247.553,78.054,247.553,107.141z M180.65,106.992
		c0,10.834,3.608,34.874,18.793,34.874c15.185,0,18.792-24.04,18.792-34.874c0-10.832-3.608-34.577-18.792-34.577
		C184.258,72.415,180.65,96.16,180.65,106.992z"/>
                <path fill="#000D44" d="M350.785,107.141c0,29.088-14.317,60.4-48.226,60.4c-33.909,0-48.226-31.313-48.226-60.4
		c0-29.087,14.468-60.398,48.226-60.398S350.785,78.054,350.785,107.141z M283.721,106.992c0,10.834,3.617,34.874,18.838,34.874
		s18.838-24.04,18.838-34.874c0-10.832-3.617-34.577-18.838-34.577S283.721,96.16,283.721,106.992z"/>
                <path fill="#000D44" d="M429.316,51.193h36.029l-44.481,52.98l48.484,58.916h-37.661l-37.513-48.527h-0.297v48.527h-29.061V51.193
		h29.061v46.152h0.297L429.316,51.193z"/>
            </g>
            <polygon fill="#5D5D5D" points="560.501,673.499 514.667,859.874 97.833,859.666 97.916,673.499 "/>
        </>
    );
};

const Icon100M = () => {
    return (
        <>
            <g>
                <polygon fill="#F32525" points="562.917,17.5 517.083,203.875 -0.083,203.667 0,17.5 	"/>
                <path fill="#000D44" d="M60.158,75.75V51.116h43.976v111.896H75.782V75.75H60.158z"/>
                <path fill="#000D44" d="M223.007,107.063c0,29.088-14.262,60.4-48.041,60.4c-33.778,0-48.041-31.313-48.041-60.4
		c0-29.087,14.412-60.4,48.041-60.4S223.007,77.977,223.007,107.063z M156.201,106.916c0,10.833,3.603,34.874,18.766,34.874
		c15.163,0,18.766-24.041,18.766-34.874c0-10.833-3.603-34.578-18.766-34.578C159.804,72.337,156.201,96.082,156.201,106.916z"/>
                <path fill="#000D44" d="M326.238,107.063c0,29.088-14.31,60.4-48.203,60.4c-33.892,0-48.203-31.313-48.203-60.4
		c0-29.087,14.461-60.4,48.203-60.4S326.238,77.977,326.238,107.063z M259.207,106.916c0,10.833,3.615,34.874,18.829,34.874
		c15.214,0,18.83-24.041,18.83-34.874c0-10.833-3.615-34.578-18.83-34.578C262.822,72.337,259.207,96.082,259.207,106.916z"/>
                <path fill="#000D44" d="M351.936,51.116h28.837l22.861,59.806l24.207-59.806h29.137l17.034,111.896h-29.286l-8.218-64.408h-0.3
		l-27.045,64.408h-11.655L371.66,98.605h-0.299l-9.414,64.408H332.81L351.936,51.116z"/>
            </g>
            <polygon fill="#000D44" points="547.833,204 593.791,204 610.833,121.5 657.25,218.625 702.666,116.584 725.083,203.875
	772.771,203.938 714.167,0 657.666,120.333 595.083,12.25 "/>
            <polygon fill="#5D5D5D" points="560.501,673.499 514.667,859.874 97.833,859.666 97.916,673.499 "/>
        </>
    )
        ;
};


const dash = () => {
    return (
        <>

        </>
    )
        ;
};

interface UtmbIconTypes {
    category: '20K' | '50K' | '100K' | '100M' | '-'
    style?: {
        height?: string;
    }
}

const StyledSvg = styled.svg`
    height: ${props => props.height || '14px'};
`;

export const UtmbIcon: React.FC<UtmbIconTypes> = (
    {
        category,
        style
    }) => {
    if (category === '-') {
        return <span style={{marginLeft: 22}}>-</span>
    }
    return (
        <StyledSvg {...style} viewBox="0 0 772.771 218.625">
            {(
                category === '20K'
            ) ? (
                <Icon20K/>
            ) : (
                category === '50K'
            ) ? (
                <Icon50K/>
            ) : (
                category === '100K'
            ) ? (
                <Icon100K/>
            ) : (
                category === '100M'
            ) ? (
                <Icon100M/>
            ) : (
                <></>
            )}
        </StyledSvg>
    )
};

export default UtmbIcon