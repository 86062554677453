
export const fetchJsonData = async (url: string, setData: any = null, fallBack: any = null) : Promise<null | any> => {
    // Set a useState provided a url
    try {
        const response = await fetch(url);
        const jsonData = await response.json();
        if (setData !== null) {
            setData(jsonData);
        }
        return jsonData;
    } catch (error) {
        console.error('Error fetching JSON file:', error);
        if (setData !== null) {
            setData(fallBack);
        }
        return null;
    }
};

