import React from 'react'

import {
    AD,
    AE,
    AL,
    AR,
    AT,
    AU,
    BA,
    BE,
    BG,
    BN,
    BO,
    BR,
    BT,
    BW,
    BY,
    CA,
    CG,
    CH,
    CL,
    CN,
    CO,
    CR,
    CV,
    CY,
    CZ,
    DE,
    DK,
    DO,
    DZ,
    EC,
    EE,
    EG,
    ES,
    ET,
    FI,
    FJ,
    FR,
    GB,
    GE,
    GP,
    GR,
    GT,
    HK,
    HR,
    HU,
    ID,
    IE,
    IL,
    IN,
    IR,
    IS,
    IT,
    JE,
    JO,
    JP,
    KE,
    KG,
    KH,
    KR,
    KW,
    KZ,
    LA,
    LB,
    LI,
    LK,
    LR,
    LT,
    LU,
    LV,
    MA,
    MC,
    ME,
    MG,
    MK,
    ML,
    MN,
    MO,
    MQ,
    MT,
    MU,
    MX,
    MY,
    NA,
    NC,
    NI,
    NL,
    NO,
    NP,
    NZ,
    OM,
    PA,
    PE,
    PF,
    PH,
    PK,
    PL,
    PR,
    PT,
    PY,
    QA,
    RE,
    RO,
    RS,
    RU,
    SA,
    SE,
    SG,
    SI,
    SK,
    SN,
    ST,
    SV,
    SY,
    TD,
    TH,
    TN,
    TR,
    TW,
    TZ,
    UA,
    UG,
    US,
    UY,
    VE,
    VN,
    XK,
    YT,
    ZA
} from 'country-flag-icons/react/3x2'


export const categoryToColor: { [key in '100M' | '100K' | '50K' | '20K' | '-']: string } = {
    '100M': '#F42525',
    '100K': '#7ddf65',
    '50K': '#FC5A00',
    '20K': '#FFFF00',
    '-': '#000D44',
}

export const categoryToBarColor: { [key in '100M' | '100K' | '50K' | '20K' | '-']: string[] } = {
    '100M': ['#bf1e1e', '#cc2121', '#d92424', '#e62828', '#f42525', '#f64e4e', '#f87777', '#fa9fa0', '#fbc8c8', '#fde0e0'],
    '100K': ['#5eb34c', '#6bc556', '#7ddf65', '#8be672', '#98ee80', '#a6f58d', '#b3f79a', '#c0f9a7', '#cdfbb3', '#dafdc0'],
    '50K': ['#c74800', '#d55400', '#e45f00', '#f26b00', '#fc5a00', '#fd7430', '#fe8e61', '#fea791', '#ffbfc2', '#ffd8e3'],
    '20K': ['#cccc00', '#d4d400', '#dddd00', '#e5e500', '#ffff00', '#ffff33', '#ffff66', '#ffff88', '#ffffaa', '#ffffcc'],
    '-': ['#00155f', '#001e8d', '#00278f', '#003192', '#003a74', '#004387', '#004c99', '#0056ac', '#0060bf', '#0069d1'],
}

export const countryCodesToNames: { [key: string]: string } = {
    'AD': 'Andorra',
    'AE': 'United Arab Emirates',
    'AL': 'Albania',
    'AR': 'Argentina',
    'AT': 'Austria',
    'AU': 'Australia',
    'BA': 'Bosnia and Herzegovina',
    'BE': 'Belgium',
    'BG': 'Bulgaria',
    'BN': 'Brunei',
    'BO': 'Bolivia',
    'BR': 'Brazil',
    'BT': 'Bhutan',
    'BW': 'Botswana',
    'BY': 'Belarus',
    'CA': 'Canada',
    'CG': 'Republic of the Congo',
    'CH': 'Switzerland',
    'CL': 'Chile',
    'CN': 'China',
    'CO': 'Colombia',
    'CR': 'Costa Rica',
    'CV': 'Cape Verde',
    'CY': 'Cyprus',
    'CZ': 'Czech Republic',
    'DE': 'Germany',
    'DK': 'Denmark',
    'DO': 'Dominican Republic',
    'DZ': 'Algeria',
    'EC': 'Ecuador',
    'EE': 'Estonia',
    'EG': 'Egypt',
    'ES': 'Spain',
    'ET': 'Ethiopia',
    'FI': 'Finland',
    'FJ': 'Fiji',
    'FR': 'France',
    'GB': 'United Kingdom',
    'GE': 'Georgia',
    'GP': 'Guadeloupe',
    'GR': 'Greece',
    'GT': 'Guatemala',
    'HK': 'Hong Kong',
    'HR': 'Croatia',
    'HU': 'Hungary',
    'ID': 'Indonesia',
    'IE': 'Ireland',
    'IL': 'Israel',
    'IN': 'India',
    'IR': 'Iran',
    'IS': 'Iceland',
    'IT': 'Italy',
    'JE': 'Jersey',
    'JO': 'Jordan',
    'JP': 'Japan',
    'KE': 'Kenya',
    'KG': 'Kyrgyzstan',
    'KH': 'Cambodia',
    'KR': 'South Korea',
    'KW': 'Kuwait',
    'KZ': 'Kazakhstan',
    'LA': 'Laos',
    'LB': 'Lebanon',
    'LI': 'Liechtenstein',
    'LK': 'Sri Lanka',
    'LR': 'Liberia',
    'LT': 'Lithuania',
    'LU': 'Luxembourg',
    'LV': 'Latvia',
    'MA': 'Morocco',
    'MC': 'Monaco',
    'ME': 'Montenegro',
    'MG': 'Madagascar',
    'MK': 'North Macedonia',
    'ML': 'Mali',
    'MN': 'Mongolia',
    'MO': 'Macau',
    'MQ': 'Martinique',
    'MT': 'Malta',
    'MU': 'Mauritius',
    'MX': 'Mexico',
    'MY': 'Malaysia',
    'NA': 'Namibia',
    'NC': 'New Caledonia',
    'NI': 'Nicaragua',
    'NL': 'Netherlands',
    'NO': 'Norway',
    'NP': 'Nepal',
    'NZ': 'New Zealand',
    'OM': 'Oman',
    'PA': 'Panama',
    'PE': 'Peru',
    'PF': 'French Polynesia',
    'PH': 'Philippines',
    'PK': 'Pakistan',
    'PL': 'Poland',
    'PR': 'Puerto Rico',
    'PT': 'Portugal',
    'PY': 'Paraguay',
    'QA': 'Qatar',
    'RE': 'Réunion',
    'RO': 'Romania',
    'RS': 'Serbia',
    'RU': 'Russia',
    'SA': 'Saudi Arabia',
    'SE': 'Sweden',
    'SG': 'Singapore',
    'SI': 'Slovenia',
    'SK': 'Slovakia',
    'SN': 'Senegal',
    'ST': 'São Tomé and Príncipe',
    'SV': 'El Salvador',
    'SY': 'Syria',
    'TD': 'Chad',
    'TH': 'Thailand',
    'TN': 'Tunisia',
    'TR': 'Turkey',
    'TW': 'Taiwan',
    'TZ': 'Tanzania',
    'UA': 'Ukraine',
    'UG': 'Uganda',
    'US': 'United States',
    'UY': 'Uruguay',
    'VE': 'Venezuela',
    'VN': 'Vietnam',
    'XK': 'Kosovo',
    'YT': 'Mayotte',
    'ZA': 'South Africa'
};

export const countryCodeToFlag = (countryCode: string) => {
    switch (countryCode) {
        case 'AD':
            return <AD className="flag"/>;
        case 'AE':
            return <AE className="flag"/>;
        case 'AL':
            return <AL className="flag"/>;
        case 'AR':
            return <AR className="flag"/>;
        case 'AT':
            return <AT className="flag"/>;
        case 'AU':
            return <AU className="flag"/>;
        case 'BA':
            return <BA className="flag"/>;
        case 'BE':
            return <BE className="flag"/>;
        case 'BG':
            return <BG className="flag"/>;
        case 'BN':
            return <BN className="flag"/>;
        case 'BO':
            return <BO className="flag"/>;
        case 'BR':
            return <BR className="flag"/>;
        case 'BT':
            return <BT className="flag"/>;
        case 'BW':
            return <BW className="flag"/>;
        case 'BY':
            return <BY className="flag"/>;
        case 'CA':
            return <CA className="flag"/>;
        case 'CG':
            return <CG className="flag"/>;
        case 'CH':
            return <CH className="flag"/>;
        case 'CL':
            return <CL className="flag"/>;
        case 'CN':
            return <CN className="flag"/>;
        case 'CO':
            return <CO className="flag"/>;
        case 'CR':
            return <CR className="flag"/>;
        case 'CV':
            return <CV className="flag"/>;
        case 'CY':
            return <CY className="flag"/>;
        case 'CZ':
            return <CZ className="flag"/>;
        case 'DE':
            return <DE className="flag"/>;
        case 'DK':
            return <DK className="flag"/>;
        case 'DO':
            return <DO className="flag"/>;
        case 'DZ':
            return <DZ className="flag"/>;
        case 'EC':
            return <EC className="flag"/>;
        case 'EE':
            return <EE className="flag"/>;
        case 'EG':
            return <EG className="flag"/>;
        case 'ES':
            return <ES className="flag"/>;
        case 'ET':
            return <ET className="flag"/>;
        case 'FI':
            return <FI className="flag"/>;
        case 'FJ':
            return <FJ className="flag"/>;
        case 'FR':
            return <FR className="flag"/>;
        case 'GB':
            return <GB className="flag"/>;
        case 'GE':
            return <GE className="flag"/>;
        case 'GP':
            return <GP className="flag"/>;
        case 'GR':
            return <GR className="flag"/>;
        case 'GT':
            return <GT className="flag"/>;
        case 'HK':
            return <HK className="flag"/>;
        case 'HR':
            return <HR className="flag"/>;
        case 'HU':
            return <HU className="flag"/>;
        case 'ID':
            return <ID className="flag"/>;
        case 'IE':
            return <IE className="flag"/>;
        case 'IL':
            return <IL className="flag"/>;
        case 'IN':
            return <IN className="flag"/>;
        case 'IR':
            return <IR className="flag"/>;
        case 'IS':
            return <IS className="flag"/>;
        case 'IT':
            return <IT className="flag"/>;
        case 'JE':
            return <JE className="flag"/>;
        case 'JO':
            return <JO className="flag"/>;
        case 'JP':
            return <JP className="flag"/>;
        case 'KE':
            return <KE className="flag"/>;
        case 'KG':
            return <KG className="flag"/>;
        case 'KH':
            return <KH className="flag"/>;
        case 'KR':
            return <KR className="flag"/>;
        case 'KW':
            return <KW className="flag"/>;
        case 'KZ':
            return <KZ className="flag"/>;
        case 'LA':
            return <LA className="flag"/>;
        case 'LB':
            return <LB className="flag"/>;
        case 'LI':
            return <LI className="flag"/>;
        case 'LK':
            return <LK className="flag"/>;
        case 'LR':
            return <LR className="flag"/>;
        case 'LT':
            return <LT className="flag"/>;
        case 'LU':
            return <LU className="flag"/>;
        case 'LV':
            return <LV className="flag"/>;
        case 'MA':
            return <MA className="flag"/>;
        case 'MC':
            return <MC className="flag"/>;
        case 'ME':
            return <ME className="flag"/>;
        case 'MG':
            return <MG className="flag"/>;
        case 'MK':
            return <MK className="flag"/>;
        case 'ML':
            return <ML className="flag"/>;
        case 'MN':
            return <MN className="flag"/>;
        case 'MO':
            return <MO className="flag"/>;
        case 'MQ':
            return <MQ className="flag"/>;
        case 'MT':
            return <MT className="flag"/>;
        case 'MU':
            return <MU className="flag"/>;
        case 'MX':
            return <MX className="flag"/>;
        case 'MY':
            return <MY className="flag"/>;
        case 'NA':
            return <NA className="flag"/>;
        case 'NC':
            return <NC className="flag"/>;
        case 'NI':
            return <NI className="flag"/>;
        case 'NL':
            return <NL className="flag"/>;
        case 'NO':
            return <NO className="flag"/>;
        case 'NP':
            return <NP className="flag"/>;
        case 'NZ':
            return <NZ className="flag"/>;
        case 'OM':
            return <OM className="flag"/>;
        case 'PA':
            return <PA className="flag"/>;
        case 'PE':
            return <PE className="flag"/>;
        case 'PF':
            return <PF className="flag"/>;
        case 'PH':
            return <PH className="flag"/>;
        case 'PK':
            return <PK className="flag"/>;
        case 'PL':
            return <PL className="flag"/>;
        case 'PR':
            return <PR className="flag"/>;
        case 'PT':
            return <PT className="flag"/>;
        case 'PY':
            return <PY className="flag"/>;
        case 'QA':
            return <QA className="flag"/>;
        case 'RE':
            return <RE className="flag"/>;
        case 'RO':
            return <RO className="flag"/>;
        case 'RS':
            return <RS className="flag"/>;
        case 'RU':
            return <RU className="flag"/>;
        case 'SA':
            return <SA className="flag"/>;
        case 'SE':
            return <SE className="flag"/>;
        case 'SG':
            return <SG className="flag"/>;
        case 'SI':
            return <SI className="flag"/>;
        case 'SK':
            return <SK className="flag"/>;
        case 'SN':
            return <SN className="flag"/>;
        case 'ST':
            return <ST className="flag"/>;
        case 'SV':
            return <SV className="flag"/>;
        case 'SY':
            return <SY className="flag"/>;
        case 'TD':
            return <TD className="flag"/>;
        case 'TH':
            return <TH className="flag"/>;
        case 'TN':
            return <TN className="flag"/>;
        case 'TR':
            return <TR className="flag"/>;
        case 'TW':
            return <TW className="flag"/>;
        case 'TZ':
            return <TZ className="flag"/>;
        case 'UA':
            return <UA className="flag"/>;
        case 'UG':
            return <UG className="flag"/>;
        case 'US':
            return <US className="flag"/>;
        case 'UY':
            return <UY className="flag"/>;
        case 'VE':
            return <VE className="flag"/>;
        case 'VN':
            return <VN className="flag"/>;
        case 'XK':
            return <XK className="flag"/>;
        case 'YT':
            return <YT className="flag"/>;
        case 'ZA':
            return <ZA className="flag"/>;
        default:
            return <></>;
    }
};