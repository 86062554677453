import React from 'react'
import {FilterCheckboxProps, YearCheckboxProps} from "./interfaces";
import {
    FaCheck,
    FaXmark,
} from 'react-icons/fa6';


export const YearCheckBox: React.FC<YearCheckboxProps> = ({index, yearFilter, ToggleYear}) => {
    const checked = yearFilter[index]
    return (
        <span className={`checkbox ${checked ? '' : 'un'}checked`}>
            {checked ? (<FaCheck onClick={() => ToggleYear(index)}/>) : (<FaXmark onClick={() => ToggleYear(index)}/>)}
        </span>
    )
}


export const FilterCheckbox: React.FC<FilterCheckboxProps> = ({checked, onClick}) => {
    return (
        <span className={`checkbox ${checked ? '' : 'un'}checked`}>{checked ? (
            <FaCheck onClick={onClick}/>
        ) : (
            <FaXmark onClick={onClick}/>
        )
        }</span>
    )
};


