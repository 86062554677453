import React from "react";
import Layout from '../components/layout';
import SEO from '../components/seo';
import UtmbGui from "../components/utmb-gui/utmb-gui";

const UtmbApp = (props: any) => {
    // const {data} = props;
    return (
        <Layout do_sticky={false} do_footy={false}>
            <SEO
                title="Trail Running Race Search"
                description="Search 40.000 race results"
                links={[
                    {href: "https://fonts.googleapis.com/css2?family=Oxanium:wght@400;700&display=swap", rel: "stylesheet"},
                    {href: "https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap", rel: "stylesheet"},
                ]}
            />
            <UtmbGui/>
        </Layout>
    );
};

export default UtmbApp;
